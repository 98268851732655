<template>
  <div class="employees__data">
    <!-- desktop view start -->
    <v-data-table
      class="data__table"
      ref="table"
      :headers="headers"
      :items="data"
      fixed-header
      hide-default-footer
      disable-pagination
      must-sort
      sort-by="name"
      v-if="!isMobile"
    >
      <template v-slot:item.name="{ item }">
        <v-tooltip bottom content-class="pkmn-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              {{ item.name }}
            </span>
          </template>
          <span>
            {{ item.name }}
          </span>
        </v-tooltip>
      </template>

      <template v-slot:item.email="{ item }">
        <v-tooltip bottom content-class="pkmn-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              {{ item.email }}
            </span>
          </template>
          <span>
            {{ item.email }}
          </span>
        </v-tooltip>
      </template>

      <template v-slot:item.role_id="{ item }">
        <v-tooltip bottom content-class="pkmn-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              {{ userHelpers.getRoleTextById(item.role_id) }}
            </span>
          </template>
          <span>
            {{ userHelpers.getRoleTextById(item.role_id) }}
          </span>
        </v-tooltip>
      </template>

      <template v-slot:item.g_role="{ item }">
        <v-tooltip bottom content-class="pkmn-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              {{ item.email }}
            </span>
          </template>
          <span>
            {{ item.g_role }}
          </span>
        </v-tooltip>
      </template>

      <template v-slot:item.group_name="{ item }">
        <v-tooltip bottom content-class="pkmn-tooltip">
          <!-- Current group -->
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <span>
                {{ item.group_name }}
              </span>
              <v-icon
                color="primary"
                class="table__tooltip-trigger"
                v-if="item.next_group_id"
              >
                mdi-circle
              </v-icon>
            </span>
          </template>
          <div>
            {{ $t('single_group') }}: <b>{{ item.group_name }}</b>
          </div>
          <div v-if="item.next_group_id">
            <br />
            {{
              $t('starting_from', {
                date: moment(item.next_group_change_at).format('DD.MM.YYYY')
              })
            }}
            {{ $t('group_is') }} <b>{{ item.next_group_name }}</b>
          </div>
        </v-tooltip>
      </template>

      <template v-slot:item.account_package_id="{ item }">
        <v-tooltip
          v-if="!item.is_pending && !item.subscription"
          bottom
          content-class="pkmn-tooltip"
          max-width="320"
        >
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-icon color="warning" class="table__tooltip-trigger"
                >mdi-circle</v-icon
              >
            </span>
          </template>
          <span>
            {{ $t('employee_no_plan_notice') }}
          </span>
        </v-tooltip>

        <v-tooltip v-else bottom content-class="pkmn-tooltip" max-width="320">
          <!-- Current package -->
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <span>
                {{ paymentHelpers.getPackageNameById(item.account_package_id) }}
              </span>
              <v-icon
                color="primary"
                class="table__tooltip-trigger"
                v-if="userHelpers.doesEmployeeHavePricingUpdate(item)"
              >
                mdi-circle
              </v-icon>
            </span>
          </template>
          <div>
            {{
              paymentHelpers.getPackageDescriptionById(item.account_package_id)
            }}
          </div>
          <div v-if="userHelpers.doesEmployeeHavePricingUpdate(item)">
            <!-- Next month's package, if there are changes -->
            <br />
            {{
              $t('starting_from', {
                date: moment(item.subscription.next_renewal_date).format(
                  'DD.MM.YYYY'
                )
              })
            }}, {{ $t('new_pricing_plan') }}
            <b>
              {{
                paymentHelpers.getPackageNameById(
                  item.subscription.next_account_package_id,
                  item.subscription.next_account_package.package.prices[0]
                    .has_pending_update
                )
              }}
            </b>
            ({{
              paymentHelpers.getPackageDescriptionById(
                item.subscription.next_account_package_id,
                item.subscription.next_account_package.package.prices[0]
                  .has_pending_update
              )
            }})
          </div>
        </v-tooltip>
      </template>

      <template v-slot:item.is_pending="{ item }">
        <div class="status-pending" v-if="item.is_pending">
          <div class="status-pending__text">
            {{
              $t(
                item.status === 'expired'
                  ? 'invitation_expired'
                  : 'settings_team_invited'
              )
            }}
          </div>
          <span
            class="status-pending__resend-button"
            v-if="resentInvitations.indexOf(item.id) > -1"
            >{{ $t('settings_send') }}</span
          >
          <a
            class="status-pending__resend-button"
            @click="resendInvitation(item)"
            v-else
            >{{ $t('settings_resend') }}</a
          >
        </div>
        <div v-else>
          {{ $t('settings_team_active') }}
        </div>
      </template>

      <template v-slot:item.account_id="{ item }">
        <div class="mobile-row__action">
          <img
            class="data__action-trigger"
            src="@/assets/button-list-menu.svg"
          />

          <div
            v-if="
              !(
                !auth.checkPermission('set-company-admin-role') &&
                item.role_id === userHelpers.getCompanyAdminRoleId()
              )
            "
            class="d-flex justify-end"
          >
            <!-- does not allow group admin editing company admin data -->
            <v-btn
              class="pkmn-button pkmn-button--no-padding data__action-button"
              text
              color="primary"
              @click="editEmployee(item)"
            >
              <img src="@/assets/button-edit.svg" />
            </v-btn>
            <v-btn
              v-if="item.user_account_id !== $store.state.userData.account_id"
              class="pkmn-button pkmn-button--no-padding data__action-button"
              text
              color="warning"
              @click="deleteEmployee(item)"
            >
              <img src="@/assets/button-delete.svg" />
            </v-btn>
          </div>
        </div>
      </template>
    </v-data-table>
    <!-- desktop view end -->

    <!-- mobile view start -->
    <div class="data__list pkmn-list pkmn-list--employee" v-else>
      <div class="list__row" dense v-for="(item, index) in data" :key="index">
        <div class="row__status">
          <label
            class="employee__status list__label d-sm-block"
            v-if="item.is_pending"
          >
            <span class="status__pending">
              {{ $t('settings_team_invited') }}
            </span>
            <span
              class="status__resent"
              v-if="resentInvitations.indexOf(item.id) > -1"
              >{{ $t('settings_send') }}</span
            >
            <a
              class="employee__resend-invite-button"
              @click="resendInvitation(item)"
              v-else
              >{{ $t('settings_resend') }}</a
            >
          </label>

          <v-chip
            class="list__chip list__chip--admin"
            small
            color="primary"
            v-if="item.role !== 'driver'"
          >
            {{ userHelpers.getRoleTextById(item.role_id) }}
          </v-chip>
        </div>

        <div class="row__data">
          {{ item.name }}
          <h3>{{ item.account_name }}</h3>
          <h3>{{ item.email }}</h3>
          <h3>{{ item.group_name }}</h3>
          <h3 v-if="item.cost_center">{{ item.cost_center }}</h3>
          <h3 v-if="item.account_package_id">
            {{ paymentHelpers.getPackageNameById(item.account_package_id) }}
            ({{
              paymentHelpers.getPackageDescriptionById(item.account_package_id)
            }})
          </h3>
          <p
            class="data__small"
            v-if="userHelpers.doesEmployeeHavePricingUpdate(item)"
          >
            {{
              $t('starting_from', {
                date: moment(item.subscription.next_renewal_date).format(
                  'DD.MM.YYYY'
                )
              })
            }}, {{ $t('new_pricing_plan') }}
            <b>
              {{
                paymentHelpers.getPackageNameById(
                  item.subscription.next_account_package_id,
                  item.subscription.next_account_package.package.prices[0]
                    .has_pending_update
                )
              }}
            </b>
            ({{
              paymentHelpers.getPackageDescriptionById(
                item.subscription.next_account_package_id,
                item.subscription.next_account_package.package.prices[0]
                  .has_pending_update
              )
            }})
          </p>
        </div>

        <div class="mobile-row__action">
          <img
            class="data__action-trigger"
            src="@/assets/button-list-menu.svg"
          />

          <div
            v-if="
              !(
                !auth.checkPermission('set-company-admin-role') &&
                item.role_id === userHelpers.getCompanyAdminRoleId()
              )
            "
          >
            <!-- does not allow group admin editing company admin data -->
            <v-btn
              class="pkmn-button pkmn-button--no-padding data__action-button"
              text
              color="primary"
              @click="editEmployee(item)"
            >
              <img src="@/assets/button-edit.svg" />
            </v-btn>
            <v-btn
              v-if="item.user_account_id !== $store.state.userData.account_id"
              class="pkmn-button pkmn-button--no-padding data__action-button"
              text
              color="warning"
              @click="deleteEmployee(item)"
            >
              <img src="@/assets/button-delete.svg" />
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <!-- mobile view end -->
  </div>
</template>

<script>
import moment from 'moment'
import auth from '@/auth'
import paymentHelpers from '@/helpers/payment'
import userHelpers from '@/helpers/user'
/**
 * Note: edit invitation is disabled until backend support
 */
export default {
  name: 'EmployeesDataTable',
  props: ['data'],
  data() {
    return {
      auth: auth,
      headers: [
        { text: this.$t('g_name'), value: 'name', cellClass: 'ellipsed-text' },
        {
          text: this.$t('g_email'),
          value: 'email',
          cellClass: 'ellipsed-text'
        },
        { text: this.$t('g_phone'), value: 'phone' },
        { text: this.$t('g_cost_center'), value: 'cost_center' },
        {
          text: this.$t('g_role'),
          value: 'role_id',
          cellClass: 'ellipsed-text'
        },
        {
          text: this.$t('single_group'),
          value: 'group_name',
          cellClass: 'ellipsed-text'
        },
        { text: this.$t('pricing'), value: 'account_package_id' },
        { text: this.$t('g_status'), value: 'is_pending', width: '120px' },
        { text: '', value: 'account_id', align: 'right', sortable: false }
      ],
      mobileBreakpoint: 769, // screen width in pixel to switch to mobile view
      resentInvitations: [],
      paymentHelpers: paymentHelpers,
      userHelpers: userHelpers,
      moment: moment
    }
  },
  computed: {
    isMobile() {
      return window.innerWidth <= this.mobileBreakpoint
    }
  },
  methods: {
    editEmployee(selectedRecord) {
      this.$emit('edit', selectedRecord)
    },
    deleteEmployee(selectedRecord) {
      this.$emit('delete', selectedRecord)
    },
    resendInvitation(selectedRecord) {
      this.resentInvitations.push(selectedRecord.id)
      this.$forceUpdate()
      this.$emit('resendInvitation', selectedRecord)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';

.status-pending__text {
  display: inline-block;

  tr:hover & {
    display: none;
  }
}

.status-pending__resend-button {
  display: none;
  font-weight: 700;

  tr:hover & {
    display: inline-block;
  }
}

.employee__status {
  font-size: 12px;
  font-weight: 500;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    display: block;
  }
}

::v-deep .ellipsed-text {
  max-width: 145px;
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}

::v-deep th {
  text-wrap: nowrap;
}

/* Tablets and mobiles */
.list__row {
  @media #{map-get($display-breakpoints, 'sm-only')} {
    display: grid;
    grid-template-columns: 144px 1fr 64px;
    column-gap: 32px;
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    display: block;
  }
}

.row__status {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    margin-bottom: 12px;
  }
}

.status__pending {
  margin-right: 8px;
}

.status__resent {
  color: $text-color-dark;
}

.data__small {
  color: $text-color-light;
  font-size: $font-size-xs;
  font-weight: 400;
}

.employee__resend-invite-button {
  text-decoration: underline;
}

.list__chip--admin {
  margin-right: 8px;
}

.mobile-row__action {
  text-align: right;
}
</style>
