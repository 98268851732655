<template>
  <v-dialog
    v-model="alwaysOpen"
    :fullscreen="isMobile"
    width="80%"
    max-width="512px"
    @click:outside="close"
  >
    <v-card class="form-dialog form-dialog--employee">
      <v-icon
        class="form-dialog__button form-dialog__button--back"
        @click="hideFullHistory"
        v-if="isShowingFullHistory"
      >
        mdi-arrow-left
      </v-icon>

      <h1 class="form-dialog__title" v-if="!isShowingFullHistory">
        {{ $t(formType === 'add' ? 'home_add_employee' : 'edit_employee') }}
      </h1>

      <v-form ref="form" v-if="!isShowingFullHistory">
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="formData.name"
              class="form__name pkmn-input"
              :label="$t('g_name')"
              validate-on-blur
              :rules="[rules.required]"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="phone"
              class="form__email pkmn-input"
              :label="$t('g_phone')"
              validate-on-blur
              :disabled="Boolean(formData.user_id)"
              :rules="phoneRules"
              @click="addCountryCode"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="formData.email"
              class="form__email pkmn-input"
              :label="$t('g_email')"
              validate-on-blur
              :disabled="Boolean(formData.user_id)"
              :rules="emailRules"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="formData.cost_center"
              class="form__cost-center pkmn-input"
              :label="`${$t('g_cost_center')} (${$t('g_optional')})`"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row
          dense
          v-if="
            !auth.checkPermission('set-company-admin-role') &&
            formData.role_id === userHelpers.getCompanyAdminRoleId()
          "
        >
          <v-col cols="12">
            <v-text-field
              :value="$t('role_company_admin')"
              class="pkmn-input"
              :label="$t('g_role')"
              disabled
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row dense v-else>
          <v-col cols="12">
            <v-select
              class="pkmn-input"
              v-model="formData.role_id"
              :items="roleOptions"
              :label="$t('g_role')"
            ></v-select>
          </v-col>
        </v-row>

        <v-row dense v-if="auth.checkPermission('view-all-groups')">
          <v-col cols="12">
            <v-select
              class="pkmn-input"
              v-model="formData.group_id"
              :disabled="!Boolean(formData.user_id)"
              :items="groupOptions"
              :label="$t('single_group')"
              :menu-props="{ maxHeight: 276 }"
            ></v-select>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <v-select
              class="pkmn-input"
              v-model="formData.account_package_id"
              :items="pricingOptions"
              :label="$t('pricing')"
            >
              <template v-slot:item="{ item, attrs, on }">
                <v-list-item
                  class="pricing-option"
                  :class="{ 'pricing-option--disabled': item.disabled }"
                  v-on="on"
                  v-bind="attrs"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-row no-gutters align="center">
                        <span class="pricing-option__name">{{
                          item.text
                        }}</span>
                        <v-spacer></v-spacer>
                        <label class="pricing-option__description">
                          {{
                            paymentHelpers.getPackageDescriptionById(item.value)
                          }}
                        </label>
                      </v-row>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
          </v-col>
        </v-row>

        <v-row dense v-if="userHelpers.doesEmployeeHavePricingUpdate(formData)">
          <v-col cols="12">
            <div class="form__pricing-notice">
              <v-icon color="primary" class="pricing-notice__icon">
                mdi-circle
              </v-icon>
              <div class="pricing-notice__text">
                <div v-if="userHelpers.doesEmployeeHavePricingUpdate(formData)">
                  <div v-html="currentPlanInfo"></div>
                  <div v-html="nextPlanInfo"></div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row
          dense
          v-if="
            formData.next_group_id &&
            !userHelpers.doesEmployeeHavePricingUpdate(formData)
          "
        >
          <v-col cols="12">
            <div class="form__pricing-notice">
              <v-icon color="primary" class="pricing-notice__icon">
                mdi-circle
              </v-icon>
              <div class="pricing-notice__text">
                {{
                  $t('starting_from', {
                    date: moment(formData.next_group_change_at).format(
                      'DD.MM.YYYY'
                    )
                  })
                }}
                {{ $t('group_is') }} <b>{{ formData.next_group_name }}</b>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row
          class="form-dialog__history"
          v-if="groupChanges && groupChanges.length > 0"
        >
          <v-col cols="12">
            <ul class="history__list history__list--short">
              <li class="history__record">
                <div
                  class="record__text"
                  style="min-width: 240px"
                  v-html="groupChanges[0].text"
                ></div>
                <span class="record__timestamp">{{
                  groupChanges[0].timestamp
                }}</span>
              </li>
              <li v-if="groupChanges.length > 1">
                <a
                  class="pkmn-link-button history__see-full"
                  @click="showFullHistory"
                >
                  {{ $t('see_full_history') }} &raquo;
                </a>
              </li>
            </ul>
          </v-col>
        </v-row>

        <v-row class="form__buttons">
          <v-col cols="6" class="text-left">
            <v-btn class="pkmn-button" color="primary" outlined @click="close">
              {{ $t('form_cancel') }}
            </v-btn>
          </v-col>
          <v-col cols="6" class="text-right">
            <v-btn
              class="pkmn-button pkmn-button--has-border"
              color="primary"
              @click="submit"
            >
              {{ $t(formType === 'add' ? 'button_add' : 'form_save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

      <div v-if="isShowingFullHistory">
        <h2 class="form-dialog__title">
          {{ $t('change_history') }}
        </h2>

        <ul class="history__list">
          <li
            class="history__record"
            v-for="(record, index) in groupChanges"
            :key="index"
          >
            <div class="record__timestamp">{{ record.timestamp }}</div>
            <div class="record__text" v-html="record.text"></div>
          </li>
        </ul>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment'
import api from '@/helpers/api'
import auth from '@/auth'
import companyHelpers from '@/helpers/company'
import paymentHelpers from '@/helpers/payment'
import userHelpers from '@/helpers/user'
import rules from '@/helpers/validation'
import i18n from '@/plugins/i18n'
import helpers from '@/helpers'
import { getPhoneCode } from 'libphonenumber-js'

export default {
  name: 'FormEmployee',
  props: ['data'],
  data() {
    return {
      auth: auth,
      formType: 'add', // form type - add or edit - determined when form first opened
      alwaysOpen: true,
      phone: '',
      formData: {
        name: '',
        order_ref: '',
        account_id: null
      },
      groupOptions: [],
      rules: rules,
      isOpening: false,
      mobileBreakpoint: 767,
      userHelpers: userHelpers,
      paymentHelpers: paymentHelpers,
      moment: moment,
      isShowingFullHistory: false
    }
  },
  computed: {
    groupChanges() {
      return this.getGroupChanges(
        /* eslint-disable camelcase */
        this.data?.group_changes ?? []
      )
    },
    isMobile() {
      return window.innerWidth <= this.mobileBreakpoint
    },
    roleOptions() {
      const roles = this.$store.state.roleOptions

      if (!roles) {
        return []
      }

      const result = []

      roles.forEach((role) => {
        // disable company admin option if the user is group admin
        if (
          role.name === 'company_admin' &&
          !auth.checkPermission('set-company-admin-role')
        ) {
          return
        }

        const option = {
          text: this.$t(`role_${role.name}`),
          value: role.id
        }

        // only load roles which have translation
        if (option.text !== `role_${role.name}`) {
          result.push(option)
        }
      })

      return result
    },
    pricingOptions() {
      const result = this.$store.getters.companyActivePricingPackages
      if (this.formData.account_package_id) {
        // if the package user subscribing to is disabled, display it in the list, but does not allow user to select it again
        if (
          !result.find(
            (item) => item.subscription_id === this.formData.account_package_id
          )
        ) {
          const packageObj = this.$store.state.pricingData.find(
            (item) => item.subscription_id === this.formData.account_package_id
          )
          // prevent data migration error crashing the whole form
          if (packageObj) {
            result.push(packageObj)
          }
        }
      }

      return result.map((item) => {
        return {
          text: item.name,
          value: item.subscription_id,
          disabled: !item.is_active
        }
      })
    },
    /**
     * Formatted content about the employee's current plan
     */
    currentPlanInfo() {
      try {
        const subscription = this.data.subscription
        return `
          ${this.$t('current_pricing_plan')} <b>${paymentHelpers.getPackageNameById(subscription.account_package_id)}</b>
          (${paymentHelpers.getPackageDescriptionById(subscription.account_package_id)}).
        `
      } catch (error) {
        return ''
      }
    },
    /**
     * Formatted content about the employee's next plan
     */
    nextPlanInfo() {
      try {
        const subscription = this.formData.subscription
        let output = `
          ${this.$t('starting_from', { date: moment(subscription.next_renewal_date).format('DD.MM.YYYY') })}, ${this.$t('new_pricing_plan')}
          <b>${subscription.next_account_package.package.prices[0].name}</b>
          (${paymentHelpers.getPackageDescriptionById(subscription.next_account_package_id)})
        `
        if (this.formData.next_group_id) {
          output += ` ${this.$t('group_and_is')} <b>${this.formData.next_group_name}</b>`
        }

        output += '.'

        return output
      } catch (error) {
        return ''
      }
    },
    emailRules() {
      return [
        (v) =>
          !!this.phone || !!v || 'Either email or phone number is required',
        (v) => !v || rules.email(v) || 'Email must be valid'
      ]
    },
    phoneRules() {
      return [
        (v) =>
          !!this.formData.email ||
          !!v ||
          'Either email or phone number is required',
        (v) => !v || rules.phone(v)
      ]
    },
    phoneCountryCode() {
      const country = helpers.getCountryByLanguage(this.$i18n.locale)
      return `+${getPhoneCode(country.toUpperCase())}`
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    submit() {
      // first, validate the form
      if (!this.$refs.form.validate()) {
        return
      }

      // prevent user reclicking the button
      this.formSubmitted = true
      switch (this.formType) {
        case 'add':
          this.submitAdd()
          break
        case 'edit':
          this.submitEdit()
          break
        default:
          break
      }
    },
    /**
     * Submit add form
     */
    async submitAdd() {
      // @todo: track data
      var data = Object.assign({}, this.formData)

      if (data.email === '') {
        data.email = null
      }

      if (this.phone !== '') {
        data.phone = this.phone
      }
      // add inviter profile id
      data.inviter_profile_id = this.$store.state.userData.profile_id_in_company
      // add invitation email language - based on the current language user is using
      data.language_code = this.$i18n.locale

      // send the invitation to the user
      const apiSettings = {
        method: 'post',
        service: 'users',
        url: `v1/groups/${this.$store.state.selectedGroup}/invitations`,
        data: data
      }

      try {
        await api.promise(apiSettings)
        this.$emit('submitAdd', data)
      } catch (error) {
        // @todo: handle this message when backend refactor the error mechanism
        this.$store.dispatch('setSystemMessage', error[0].message)
      }
    },
    /**
     * Submit edit form
     */
    async submitEdit() {
      const data = {
        cost_center: this.formData.cost_center,
        role_id: this.formData.role_id,
        // add inviter profile id
        inviter_profile_id: this.$store.state.userData.profile_id_in_company,
        // add invitation email language - based on the current language user is using
        language_code: this.$i18n.locale,
        account_package_id: this.formData.account_package_id
      }

      if (this.formData.user_id) {
        // case: editing user
        data.user_account_id = this.formData.account_id
        // backend store name in invite table, but first_name & last_name in user table
        data.first_name = this.formData.name
        data.last_name = ' '
        // edit employee pricing - if employee has a package and the edit move the employee to a new one
        // backend need old & new subscription id
        if (this.data.account_package_id) {
          // old subscription id
          data.subscription_id = this.data.subscription.id
        }

        let originalGroupId = this.data.group_id
        if (this.data.next_group_id !== null) {
          originalGroupId = this.data.next_group_id
        }

        if (originalGroupId !== this.formData.group_id) {
          data.group_id = companyHelpers.getGroupById(
            this.formData.group_id
          ).account_id
        }
      } else {
        // case: editing invitation
        data.name = this.formData.name
        data.email = this.formData.email
        if (this.phone !== '') {
          data.phone = this.phone
        }
      }
      if (data.email === '') {
        delete data.email
      }

      const url = this.formData.user_id
        ? `v1/groups/${this.data.group_id}/users/${this.data.user_id}`
        : `v1/groups/${this.data.group_id}/invitations/${this.formData.id}`

      // send the invitation to the user
      const apiSettings = {
        method: 'put',
        service: 'users',
        url: url,
        data: data
      }

      try {
        await api.promise(apiSettings)
        this.$emit('submitEdit', data)
      } catch (error) {
        this.$store.dispatch('setSystemMessage', error)
      }
    },

    showFullHistory() {
      this.isShowingFullHistory = true
    },

    hideFullHistory() {
      this.isShowingFullHistory = false
    },

    getGroupChanges(groupChanges) {
      const result = []

      groupChanges.forEach((change) => {
        if (change.canceled_at) {
          result.push({
            text: `${i18n.t('group_change_canceled', { old_group: change.group_old, new_group: change.group_new })}`,
            timestamp: moment(change.canceled_at).format('DD.MM.YYYY HH:mm:ss')
          })
        }

        if (change.applied_at) {
          result.push({
            text: `${i18n.t('group_change_applied', { new_group: change.group_new })}`,
            timestamp: moment(change.applied_at).format('DD.MM.YYYY HH:mm:ss')
          })
        }

        let changedBy = change.changed_by
        if (changedBy === 'ParkMan Customer Service') {
          changedBy = `${i18n.t('parkman_admin')}`
        }

        result.push({
          text: `
            ${i18n.t('current_group', { group: change.group_old })}
            ${i18n.t('starting_group', {
              date: moment(change.apply_at).format('DD.MM.YYYY'),
              new_group: change.group_new
            })}
            ${i18n.t('group_change_pending', { full_name: changedBy })}.
          `,
          timestamp: moment(change.created_at).format('DD.MM.YYYY HH:mm:ss')
        })
      })

      return result
    },
    addCountryCode() {
      if (!this.phone.startsWith('+')) {
        this.phone = this.phoneCountryCode + this.phone
      }
    }
  },
  mounted() {
    // build group options
    this.groupOptions = companyHelpers.buildGroupOptions()

    // determine the form type
    this.formType =
      this.data.user_id || this.data.inviter_profile_id ? 'edit' : 'add'

    // prefill data based on form type
    // set the data in case of edit form
    this.formData = Object.assign({}, this.data)
    const defaultPackage = this.$store.getters.companyDefaultPackage

    switch (this.formType) {
      case 'add':
        // set default role as driver when adding
        this.formData.role_id = userHelpers.getDriverRoleId()
        this.formData.group_id = this.$store.state.selectedGroup

        // when adding new employee, pre-select default package
        // 'account_package_id' params required from backend is equivalent to `subscription_id` returned in the get-company-packages endpoint
        this.formData.account_package_id = defaultPackage
          ? defaultPackage.subscription_id
          : null

        break
      case 'edit':
        this.formData.group_id = this.data.group_id
        this.phone = this.data.phone
        // if user move to a new package next month, pre-select the new package instead
        if (userHelpers.doesEmployeeHavePricingUpdate(this.formData)) {
          this.formData.account_package_id =
            this.data.subscription.next_account_package_id
        }

        // Pre-select the new group when user is going to be moved to another group
        if (this.data.next_group_id) {
          this.formData.group_id = companyHelpers.getGroupByAccountId(
            this.data.next_group_id
          )?.id
        }
        break
      default:
        break
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';

.form-dialog {
  padding: 48px 80px;
  text-align: left;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    padding: 40px 16px !important;
  }
}

.form-dialog__title {
  margin-bottom: 32px;
}

.form__buttons {
  margin-top: 16px !important;
}

.form__buttons .pkmn-button {
  width: 100%;
}

.pricing-option__name {
  max-width: 144px;
  white-space: normal;
  word-break: break-word;

  .pricing-option--disabled & {
    color: rgba(0, 0, 0, 0.38);
  }
}

.pricing-option__description {
  max-width: 144px;
  white-space: normal;
  text-align: right;
  color: $text-color-dark;
  opacity: 0.8;

  .pricing-option--disabled & {
    color: rgba(0, 0, 0, 0.38);
  }
}

.form__pricing-notice {
  padding: 12px 16px;
  border: 1px solid $color-brand;
  border-radius: 4px;

  display: grid;
  grid-template-columns: 12px 1fr;
  column-gap: 12px;
}

.pricing-notice__icon {
  font-size: 12px;
}

.pricing-notice__text {
  font-size: 12px;
  line-height: 120%;
  color: #000;
}

.history__list {
  list-style: none;
  padding: 0;
  max-height: 70vh;
  overflow-y: auto;

  &--short {
    font-weight: 400;
    font-size: $font-size-xs;
    line-height: 120%;
    color: $text-color-dark;
    padding: 8px 12px;
    background: rgba(240, 240, 240, 0.6);
    border-radius: 4px;
  }
}

.history__record {
  margin-top: 4px;

  &:not(:last-child) {
    padding-bottom: 12px;
    border-bottom: 1px solid $color-line;
  }

  .history__list--short & {
    display: flex;
    font-weight: 400;
    padding-bottom: 0;
    border-bottom: none;
  }
}

.record__timestamp {
  font-weight: 600;
  margin-bottom: 8px;

  .history__list--short & {
    max-width: 80px;
    font-weight: 400;
    margin-bottom: 0;
    padding-left: 16px;
    text-align: right;
  }
}

.history__see-full {
  font-weight: 400;
  font-size: $font-size-xs;
  line-height: 120%;
  display: block;
  margin-top: 12px;
  color: #666;
  text-decoration: underline;
}
</style>
